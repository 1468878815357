<template>
  <v-layout row wrap class="my-12 mx-0" align-center>
    <v-flex xs12 class="mt-12">
      <div class="mt-11 mx-2 justify-center" style="display: grid">
        <h2 class="font-weight-medium display-3 nightText">
          Tuotteet ja palvelut
        </h2>
        <v-divider style="border-color: #004b55" class="mx-12 mt-5" />
      </div>
    </v-flex>
    <v-flex xs12 class="my-0">
      <v-container grid-list-xl>
        <v-layout row wrap align-start justify-center class="mx-0">
          <v-flex xs12 xl6>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center ma-2">
                <div
                  class="headline text-center nightText font-weight-medium"
                  style="word-break: normal"
                >
                  Tutkitusti laadukkaat ja tyylikkäät aurinkopaneelit
                  (Amerisolar)
                </div>
              </v-card-title>
              <v-card-text class="d-flex">
                <v-layout :column="$vuetify.breakpoint.smAndDown" align-center>
                  <v-img
                    :src="`${publicPath}/paneeli.png`"
                    alt="LuontoEnergia"
                    position="center center"
                    max-width="150"
                  />
                  <v-list class="ml-4 transparent font-weight-medium title">
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon>Pitkä
                      kokemus, perustettu 1985 USA:ssa
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Tuottavuustakuu 30 vuotta (yli 80%)
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Tuotetakuu 12 vuotta
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Paneeleiden käyttöikä keskimäärin 40-50 vuotta
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 xl6>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center ma-2">
                <div
                  class="headline text-center nightText font-weight-medium"
                  style="word-break: normal"
                >
                  Asennusjärjestelmät (Orima)
                </div>
              </v-card-title>
              <v-card-text class="d-flex">
                <v-layout :column="$vuetify.breakpoint.smAndDown" align-center>
                  <v-img
                    :src="`${publicPath}/kiinnikkeet.jpg`"
                    alt="LuontoEnergia"
                    position="center center"
                    max-width="200"
                  />
                  <v-list class="ml-4 transparent font-weight-medium title">
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Kotimainen laadukas tuote
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Suunniteltu vaativiin olosuhteisiin
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Tuotetakuu 30 vuotta
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 xl6>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div
                  class="headline text-center nightText font-weight-medium ma-2"
                  style="word-break: normal"
                >
                  Invertterit (Huawei)
                </div>
              </v-card-title>
              <v-card-text class="d-flex">
                <v-layout :column="$vuetify.breakpoint.smAndDown" align-center>
                  <v-img
                    :src="`${publicPath}/invertteri.png`"
                    alt="LuontoEnergia"
                    position="center center"
                    max-width="150"
                  />
                  <v-list class="ml-4 transparent font-weight-medium title">
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Maailmanlaajuinen tunnettu yritys
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon>
                      Tuotetakuu 5 vuotta
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Reaaliaikainen seuranta omasta voimalasta kännykällä,
                      web-sivulla
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon>Akusto
                      liitettävissä jälkikäteen kaikkiin malleihin
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 xl6>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center ma-2">
                <div
                  class="headline text-center nightText font-weight-medium"
                  style="word-break: normal"
                >
                  Muut tuotteet
                </div>
              </v-card-title>
              <v-card-text class="d-flex">
                <v-layout :column="$vuetify.breakpoint.smAndDown" align-center>
                  <v-img
                    :src="`${publicPath}/laturi.png`"
                    alt="LuontoEnergia"
                    position="center center"
                    max-width="200"
                  />
                  <v-list class="ml-4 transparent font-weight-medium title">
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon>Älykkäät
                      sähköauton latausyksiköt
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon>Älykkäät
                      ohjausjärjestelmät
                    </v-list-item>
                    <v-list-item>
                      <v-icon class="nightText"> {{ circle }} </v-icon
                      >Ilmalämpöpumput
                    </v-list-item>
                  </v-list>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center ma-2">
                <div
                  class="display-1 text-center nightText font-weight-medium"
                  style="word-break: normal"
                >
                  Avaimet käteen -palvelu
                </div>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-layout row class="ma-0 text-center title">
                    <v-flex xs12>
                      Me haemme tarvittavat luvat puolestanne
                    </v-flex>
                    <v-flex xs12>
                      Tuotteet toimitetaan sovittuna aikana ja asennetaan
                      TUKES:in asennusmääräyksiä noudattaen
                    </v-flex>
                    <v-flex>
                      Opastamme teitä myös järjestelmän käyttöönotossa
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mdiCircleMedium } from "@mdi/js";
export default {
  name: "products",
  data: () => ({
    publicPath: process.env.VUE_APP_DEPLOY_URL || "",
    circle: mdiCircleMedium
  })
};
</script>
